






























































































































































import {Component, Vue, Watch} from "vue-property-decorator"
import Order, { OrderType, OrderSelect } from "@/models/Order"
import OrderService from "@/services/OrderService"
import OrderDetailsDialog from "@/components/dialogs/OrderDetailsDialog.vue"
import OrderStatusComponent from "@/components/OrderStatusComponent.vue"
import OrderDetail from "@/models/OrderDetail"
import User from "@/models/User"
import SessionModule from "@/store/SessionModule"
import { getModule } from "vuex-module-decorators"
import Client from "@/models/Client"
import Options from "@/models/vue/Options"
import LangModule from "@/store/LangModule"
import ClientService from "@/services/ClientService"

@Component( { components: { OrderStatusComponent, OrderDetailsDialog } } )
export default class OrdersTab extends Vue {

  order: Order = new Order

  loading: boolean = false
  sendButtonDisabled = true
  page: number = 1
  pageCount: number = 0
  itemsPerPage: number = 10
  totalItems: number = 0
  search: string = ""
  orders: Order[] = []
  selected: Order[] = []
  dialog: boolean = false
  options: Options = new Options()
  picker: string = ""
  dates: string[] = [this.picker]
  clients: Client[] = []
  client: Client = new Client()
  commercial: User = new User()
  commercials: User[] = []
  clientsPage: number = 0
  // @ts-ignore
  orderType: OrderType | null = null
  sessionModule: SessionModule = getModule(SessionModule)
  isCommercial = this.sessionModule.session.authorities.map(e => e.title == "commercial")[0]
  all: boolean = false
  lang: any = getModule(LangModule).lang
  interval = setInterval(() => { this.refresh() }, 10000)

  headers = [
    { text: this.lang.orderReferenceHeader, align: 'center', value: 'ref', width: 'auto' },
    { text: this.lang.buyDateAtHeader, align: 'center', value: 'createdAt', width: 'auto' },
    { text: this.lang.stateHeader, align: 'center', value: 'state', width: 'auto' },
    { text: this.lang.total, align: 'center', value: 'totalAmount', width: 'auto' }
  ]

  beforeDestroy() {
    clearInterval(this.interval)
  }


	orderTypes: OrderSelect[] = [
		{name: this.lang.waitingStatus, value: OrderType.WAITING},
		{name: this.lang.sendStatus, value: OrderType.SEND},
		// {name: this.lang.finishStatus, value: OrderType.FINISH},
		{name: this.lang.draftStatus, value: OrderType.DRAFT},
	]

  indicators = [
    { name: this.lang.inProgressStatus, color: "purple lighten-3" },
    { name: this.lang.sendStatus, color: "blue lighten-2" },
    { name: "Por confirmar", color: "deep-purple lighten-1" },
    { name: "Retrasado", color: "grey" },
    { name: "Cancelado", color: "light-blue lighten-4" }
  ]

  created() {
    this.refresh()
    this.interval
  }

  async refresh() {
		await ClientService.getClients(this, this.clients,this.page - 1, this.itemsPerPage, "", true, true)
		if (this.isCommercial || this.all) {
      await OrderService.getMyOrdersByUser(this, this.orders, this.page - 1, this.itemsPerPage, this.sessionModule.session!.user!.id!, this.search, this.orderType, this.client.id, this.dates[0], this.dates[1])
    } else {
      await OrderService.getMyOrders(this, this.orders, this.page - 1, this.itemsPerPage, this.commercial.id!,this.search, this.orderType, this.client.id, this.dates[0], this.dates[1])
		}
	}

  log(v: any) {
    console.log(v)
  }

  getTotalPrice(orderDetails: OrderDetail[]): number {
    let totalPrice: number = 0
    orderDetails.forEach(orderDetail => {
      totalPrice += (Number(orderDetail.product!.commercialRate) * Number(orderDetail.quantity))
    })
    return totalPrice
  }

  rowClick(item: Order) {
    this.order = item
    this.dialog = true
  }

  closeDialog() {
    this.dialog = false
    this.refresh()
  }

  @Watch('loading')
	onLoadingChanged() {
		if (!this.loading) {
			this.pageCount = Math.round(this.totalItems / this.itemsPerPage)
		}
	}

  @Watch('page')
	onPageChanged() {
		this.refresh()
	}

	@Watch('orderType')
	onOrderTypeChanged() {
		this.refresh()
	}

  @Watch('client')
  onClientChanged() {
    this.refresh()
  }

}
