


































import {Component, Vue} from "vue-property-decorator";

@Component
export default class ChangePasswordTab extends Vue {
	showActualPassword: boolean = false
	showNewPassword: boolean = false
	showNewConfirmPassword: boolean = false
	passwordRules = [
		(v: string) => v.length > 0 ? true : "Contraseña requerida"
	]
}
