





















































































































import {Component, Vue} from "vue-property-decorator";
import AddressTab from "@/components/tabs/AddressTab.vue";
import OrdersTab from "@/components/tabs/OrdersTab.vue";
import ChangePasswordTab from "@/components/tabs/ChangePasswordTab.vue";

@Component({components:{
		AddressTab,
		OrdersTab,
		ChangePasswordTab
}})
export default class DashboardView extends Vue {
	tab = null
}
