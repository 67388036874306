










































































import {Component, Prop, PropSync, Vue, Watch} from "vue-property-decorator"
import ConstantTool from "@/services/tool/ConstantTool"
import { getModule } from "vuex-module-decorators"
import SnackbarModule from "@/store/SnackbarModule"
import SessionModule from "@/store/SessionModule"
import LangModule from "@/store/LangModule"
import OrderDetail from "@/models/OrderDetail"

@Component
export default class OrderDetailListComponent extends Vue {

  @Prop() readonly orderDetail!: OrderDetail
  @Prop() readonly addMode!: boolean
  @Prop() readonly disabled?: boolean
  @Prop() readonly isPvp?: boolean

  // @PropSync('quantity') quantity!: number

  clientDialog: boolean = false

  get price() { return Number(this.orderDetail.product!.rates![this.rateId! - 1].price).toFixed(2) }
  get pricePvp() { return Number(this.orderDetail.product!.commercialRate!).toFixed(2) }
  get	total() { return (Number(this.orderDetail.product!.rates![this.rateId! - 1].price) * (this.orderDetail.quantity!)).toFixed(2) }
  get totalPvp() { return Number(this.orderDetail.product!.commercialRate! * this.orderDetail!.quantity!).toFixed(2) }

  lang: any = getModule(LangModule).lang
  sessionModule: SessionModule = getModule(SessionModule)
  isDistributor = this.sessionModule.session.authorities.map(e => e.title == "distributor")[0]
  BASE_URL = ConstantTool.BASE_URL
  image: string = ""
  amount: number = 0

  get rateId(): number { return this.sessionModule.session.user.rateId! }

  addProduct() {
    this.$emit('openProductDialog', this.orderDetail)
  }

  remove() {
    this.$emit('remove', this.orderDetail)
  }

  created() {
    this.refresh()
  }

  errorHandler() {
    this.image = "../assets/ban.svg"
    this.$forceUpdate()
  }

  refresh() {
    this.image = `${this.BASE_URL}/storage/ftp/art_${this.orderDetail.product!.connectorId?.toLowerCase()}_1_tmb.jpg`
  }

  addFavourite() {
    getModule(SnackbarModule).makeToast(`El producto ha sido marcado como favorito.`)
  }

  @Watch('orderDetail')
  onProductChanged() {
    this.refresh()
  }

  @Watch('orderDetail.quantity')
  onQuantityChanged() {
    this.$emit("onQuantityChanged", this.orderDetail)
  }

  log(v: any) {
    console.log(v)
  }
}
