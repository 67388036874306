












































































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator"
import SessionModule from "@/store/SessionModule"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import Order, { OrderType } from "@/models/Order"
import Client from "@/models/Client"
import ClientService from "@/services/ClientService"
import AddressService from "@/services/AddressService"
import Address from "@/models/Address"
import ConstantTool from "@/services/tool/ConstantTool"
import OrderService from "@/services/OrderService"
import DialogModule from "@/store/DialogModule"
import Dialog from "@/models/vue/Dialog"
import SnackbarModule from "@/store/SnackbarModule"
import CartTool from "@/services/tool/CartTool"
import CartModule from "@/store/CartModule"
import ProductListComponent from "../ProductListComponent.vue"
// @ts-ignore
import html2pdf from 'html2pdf.js'
import html2canvas from "html2canvas";
import OrderDetailListComponent from "@/components/products/OrderDetailListComponent.vue";
import OrderDetail from "@/models/OrderDetail";

@Component({ components: { ProductListComponent, OrderDetailListComponent } })
export default class OrderDetailsDialog extends Vue {
  
  @Prop() readonly dialog!: boolean
  @Prop() readonly order!: Order
  lang: any = getModule(LangModule).lang
  cartModule: CartModule = getModule(CartModule)
  sessionModule: SessionModule = getModule(SessionModule)
  clients: Client[] = []
  orderProductsId: number[] = [] 
  newAddress: Address = new Address()
  shippingAddresses: Address[] = []
  BASE_URL = ConstantTool.BASE_URL

  clientRules = [(v: Client) => v ? true : this.lang.requiredInput]
  addressRules = [(v: Address) => v ? true : this.lang.requiredInput]

  created() { this.refresh() }

  refresh() {
    ClientService.getMyClients(this, this.clients, 0, 10, "", true, this.sessionModule.session!.user!.id!)
  }

  removeProduct(orderDetail: OrderDetail) {
    if (this.isEditable) {
      this.order.orderDetails = this.order.orderDetails!!.filter(v => orderDetail.id != v.id)
    }
  }

  @Watch('order.client')
  watchClient() {
    this.getTotalTaxBase()

    if (this.order.client) {
      AddressService.getAddressByClient(this, this.shippingAddresses, this.order.client!.id!)
    }
  }

  close() {
    getModule(DialogModule).showDialog(new Dialog(this.lang.warning, "¿Desea salir sin guardar los cambios?", () => {
      this.$emit("close")
    })) 
  }

  async draft() {
    if (this.order.orderDetails!!.length != 0) {
      getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.patchOrderConfirmation, () => {
        OrderService.patchOrder(this, this.order).then(() => {this.$emit("close")})
      }))
    } else {
      getModule(SnackbarModule).makeToast("Los pedidos requieren al menos un producto.")
    }
  }

  deleteOrder() {
    if (this.isEditable) {
      getModule(DialogModule).showDialog(new Dialog(this.lang.warning, this.lang.deleteOrderConfirmation, () => {
        OrderService.deleteOrder(this, this.order.id!).then(() => {this.$emit("close")})
      })) 
    } else {
      getModule(SnackbarModule).makeToast("Los pedidos enviados no pueden ser eliminados.")
    }
  }

  cloneOrder() {
    getModule(DialogModule).showDialog(new Dialog(this.lang.warning, "¿Desea clonar su pedido? Los productos en el carrito serán reemplazados.", () => {
      this.order.orderDetails?.forEach((orderDetail) => { CartTool.addProduct(this.cartModule ,orderDetail.product!) })
      this.$emit("close")
    })) 
  }

  get isEditable() {
    return (this.order.orderType == 'DRAFT') || (this.order.orderType == 'WAITING')
  }

  getTotalTaxBase() {
    let totalTaxBase: number = 0
    this.order.orderDetails?.forEach(orderDetail => {
      totalTaxBase += (Number(orderDetail.product!.commercialRate) * Number(orderDetail.quantity))
    })

    return totalTaxBase
  }

	exportToPDF () {
		html2canvas(document.getElementById("eso")!).then(canvas => {
			setTimeout(() => {
				this.myRenderFunction(canvas)
			}, 1000)
		})
	}

	myRenderFunction(canvas: any) {
		var a = document.createElement('a');
		// toDataURL defaults to png, so we need to request a jpeg, then convert for file download.
		a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
		a.download = 'somefilename.jpg';
		a.click();
	}
}
